import './CustomCard.scss';
import { Card } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const { Meta } = Card;

interface CustomCardProps {
  icon: React.ElementType;
  title: string;
  text: string | number;
  path?: string;
}

const CustomCard: React.FC<CustomCardProps> = ({ icon: Icon, title, text, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <Card className="card" hoverable>
      <Meta
        avatar={<Icon className="icon" />}
        title={<span className="title">{title}</span>}
        description={
          <span className="description" onClick={handleClick} style={{ cursor: path ? 'pointer' : 'default' }}>
            {typeof text === 'number' ? text.toString() : text}
          </span>
        }
      />
    </Card>
  );
};

export default CustomCard;
