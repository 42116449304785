import './Sidebar.scss';

import { Menu, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  HomeOutlined,
} from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type: "item" | "group" = "item",
  style?: React.CSSProperties,
  id?: string,
): MenuItem {
  const mergedStyle = icon === undefined ? { ...defaultStyle, paddingLeft: 4, paddingRight: 0, fontSize: 13 } : { ...defaultStyle };
  return {
    key,
    icon,
    children,
    label,
    type,
    style: mergedStyle,
    id,
  } as MenuItem;
}
const defaultStyle: React.CSSProperties = {
  fontSize: "14px",
};
const items: MenuItem[] = [
  getItem("Dashboard", "/user-dashboard", <HomeOutlined />, undefined, undefined, undefined, "user-dashboard-tab"),
];

const UserSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeState, setActiveState] = useState(location.pathname);

  useEffect(() => {
    if (activeState !== location.pathname) {
      setActiveState(location.pathname);
    }
  }, [location, activeState]);

  const handleNavigateMenu = (items: any) => {
      navigate(items.key);
  };

 
  return (
    <section id="menu-sidebar" className="sidebar">
      <Menu
        id="menu-bar-sidebar"
        mode="inline" 
        className="menu-bar"
        items={items}
        onClick={(items) => handleNavigateMenu(items)}
        selectedKeys={[activeState]}
      />
    </section>
  );
};

export default UserSidebar;
