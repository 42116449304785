import './ChartComponent.scss';

import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import React from 'react';

interface BarChartProps {
  data?: Array<{ category: string; value: number }>;
}

const BarChartComponent: React.FC<BarChartProps> = ({ data = [] }) => { 

  return (
    <div className="chart-container">
      <h2>Bar Chart</h2>
      <div className="chart">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart layout="vertical" data={data}>
            <XAxis type="number" />
            <YAxis type="category" dataKey="category" />
            <Tooltip />
            <Legend verticalAlign="top" align="left" />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
