import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { SelectChangeEvent } from "@mui/material";
import "./WebScrapingMain.scss";

export default function WebScraping() {
  const CustomPaper = (props: any) => (
    <Paper
      {...props}
      sx={{
        cursor: "pointer",
        color: "white",
        backgroundColor: "rgba(255, 255, 255, 0.1)", // Glossy background
        backdropFilter: "blur(18px)", // Blurred background effect
        "& .MuiAutocomplete-listbox": {
          maxHeight: "300px", // Scrollable max height
        },
        "& .MuiMenuItem-root": {
          color: "white",
        },
        "& .MuiMenuItem-root:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker on hover
        },
        "& .Mui-selected": {
          backgroundColor: "rgba(255, 255, 255, 0.3) !important", // Selected item background
        },
      }}
    />
  );
  interface StateOption {
    state_code: string;
    state_name: string;
  }
  interface SerpRowData {
    url: string;
    rank_group: string;
    domain: string;
    title: string;
    description: string;
  }
  type PageScrappedRow = {
    level: number;
    h_title: string;
    primary_content?: string;
    secondary_content?: string;
  };
  type AutocompleteReason =
    | "select-option"
    | "remove-option"
    | "create-option"
    | "clear";

  const [gapValue, setGapValue] = React.useState(10);
  // console.log("gapValue", typeof gapValue, gapValue);

  const [loading, setLoading] = React.useState(false);
  const [loadingStates, setLoadingStates] = React.useState(false);

  const [loadingForUrl, setLoadingForUrl] = React.useState(false);

  const [countdown, setCountdown] = useState(10);
  const [scrapType, setScrapType] = useState("");
  console.log("scrapType", scrapType);

  const [fieldVal, setFieldVal] = useState<string>("");
  const [keywordVal, setKeywordVal] = useState<string>("");
  const [limit, setLimit] = useState<string>("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // sitemap pagination states
  const [pageSite, setPageSite] = React.useState(0);
  const [rowsPerPageSite, setRowsPerPageSite] = React.useState(5);

  const [pageSerp, setPageSerp] = React.useState(0);
  const [rowsPerPageSerp, setRowsPerPageSerp] = React.useState(5);

  const [pageRows, setPageRows] = useState([]);
  const [pageScrappedData, setPageScrappedData] = useState<PageScrappedRow[]>(
    []
  );
  const [SERPScrappedData, setSERPScrappedData] = useState<SerpRowData[]>([]);
  const [sitemapScrappedData, setSitemapScrappedData] = useState([]);
  console.log("sitemapscrappeddata", sitemapScrappedData);

  const [keywordTableShow, setKeywordTableShow] = useState(false);
  const [pageScrapTableShow, setPageScrapTableShow] = useState(false);
  const [SERPTableShow, setSERPTableShow] = useState(false);
  const [sitemapTableShow, serSitemapTableShow] = useState(false);

  const [fieldError, setFieldError] = React.useState(false);
  const [scrapTypeError, setScrapTypeError] = React.useState(false);

  const [countryCityVal, setCountryCityVal] = useState({
    country: "",
    state: "",
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState<StateOption[]>([]);
  const [loadedCount, setLoadedCount] = React.useState(10);

  const [filteredStates, setFilteredStates] = useState<StateOption[]>([]); // Type the array as StateOption[]
  const [searchText, setSearchText] = useState<string>(""); // Type searchText as a string
  const [loadedCountForState, setLoadedCountForState] = useState<number>(10); // Initial count for loaded items
  const scrollPositionRef = React.useRef<number>(0); // Type scrollPositionRef as a number
  const listboxRef = React.useRef<HTMLUListElement | null>(null); // Type listboxRef to a possible HTMLDivElement or null

  React.useEffect(() => {
    const filtered = searchText
      ? states.filter((state) =>
          state.state_name.toLowerCase().includes(searchText.toLowerCase())
        )
      : states;

    setFilteredStates(filtered.slice(0, loadedCountForState));
  }, [loadedCountForState, searchText, states]);

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const listboxNode = listboxRef.current;
    if (listboxNode) {
      const isAtBottom =
        listboxNode.scrollHeight - listboxNode.scrollTop ===
        listboxNode.clientHeight;

      if (isAtBottom && loadedCountForState < states.length && !searchText) {
        scrollPositionRef.current = listboxNode.scrollTop;

        setLoadedCountForState((prevCount) =>
          Math.min(prevCount + 10, states.length)
        );
      }
    }
  };

  React.useEffect(() => {
    if (listboxRef.current) {
      listboxRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [filteredStates]);

  console.log("countries", countries);
  console.log("countryCityVal", countryCityVal);

  // State to store selected URLs
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);

  const [SERPErrors, setSERPErrors] = useState({
    keyword: false,
    limit: false,
    country: false,
    state: false,
  });

  const [errorResText, setErrorResText] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const pageSearchApiUrl =
    "https://utilities.seedinternaldev.xyz/api/parse-content";
  const SERPApiUrl = "https://utilities.seedinternaldev.xyz/api/post-serp-task";
  const scrapUrlsApiUrl =
    "https://utilities.seedinternaldev.xyz/api/scrape-urls";
  const siteMapSearchUrl = "https://utilities.seedinternaldev.xyz/api/site-map";

  const locationGetUrl =
    "https://utilities.seedinternaldev.xyz/api/iso-details";
  const stateGetUrl = `https://utilities.seedinternaldev.xyz/api/states/${countryCityVal?.country}`;

  React.useEffect(() => {
    // Function to update gap based on window width
    const updateGapValue = () => {
      if (window.innerWidth > 900) {
        setGapValue(1); // Set gap to 1 for screens wider than 768px
      } else {
        setGapValue(5); // Set gap to 0 for screens smaller than 768px
      }
    };

    // Set initial gap value based on current window width
    updateGapValue();

    // Add event listener for window resize
    window.addEventListener("resize", updateGapValue);

    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", updateGapValue);
    };
  }, []);

  React.useEffect(() => {
    axios.get(locationGetUrl).then((res) => {
      // console.log(res?.data?.slice(0, 10), "locRes");

      setCountries(res?.data);

      // setStates(res?.data);
    });
  }, []);
  console.log("states", states);

  React.useEffect(() => {
    if (countryCityVal?.country) {
      setLoadingStates(true);
      axios
        .get(stateGetUrl)
        .then((res) => {
          setStates(res?.data);
          setLoadingStates(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingStates(false);
        });
    }
  }, [countryCityVal?.country]);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (sitemapScrappedData && sitemapScrappedData?.length > 0) {
      serSitemapTableShow(true);
    }
    if (pageScrappedData && pageScrappedData?.length > 0) {
      setPageScrapTableShow(true);
    }

    if (SERPScrappedData && SERPScrappedData?.length > 0) {
      setSERPTableShow(true);
    }
  }, [pageScrappedData, SERPScrappedData, sitemapScrappedData]);

  const handleSearchBtn = () => {
    const isFieldValEmpty = !fieldVal?.trim();
    const isScrapTypeEmpty = !scrapType?.trim();

    // Check if either fieldVal or scrapType is empty
    if (isFieldValEmpty || isScrapTypeEmpty) {
      setFieldError(isFieldValEmpty);
      setScrapTypeError(isScrapTypeEmpty);
      return; // Exit if validation fails
    }

    setFieldError(false);
    setScrapTypeError(false);
    setKeywordTableShow(true);
    const objForApi = {
      url: fieldVal,
      enable_javascript: true,
      store_raw_html: true,
    };
    setLoading(true);
    setCountdown(10);
    if (fieldVal && scrapType && scrapType === "Page Scraper") {
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      axios
        .post(pageSearchApiUrl, [objForApi])
        .then((response) => {
          console.log(response, "resforcheckpage");

          setPageScrappedData(response?.data?.data);
          console.log(response);
        })
        .catch((error) => {
          console.log(error, "error");
          if (error?.response?.data?.error) {
            setErrorResText(error?.response?.data?.error);
            setErrorShow(true);
            setTimeout(() => {
              setErrorShow(false);
              setErrorResText(""); // Clear the error message
            }, 3000);
          }
        })
        .finally(() => {
          console.log("finally");
          clearInterval(timer);
          setLoading(false);
        });
    }
  };

  const handleSearchBtnSERP = () => {
    const isKeywordValEmpty = !keywordVal?.trim();
    const isLimitValEmpty = !limit?.trim();

    const isScrapTypeEmpty = !scrapType?.trim();
    const isCountryEmpty = !countryCityVal?.country?.trim();
    const isStateEmpty = !countryCityVal?.state?.trim();

    // Check if either fieldVal or scrapType is empty
    if (
      isScrapTypeEmpty ||
      isKeywordValEmpty ||
      isLimitValEmpty ||
      isCountryEmpty ||
      isStateEmpty
    ) {
      setScrapTypeError(isScrapTypeEmpty);
      setSERPErrors((prevErrors) => ({
        ...prevErrors,
        keyword: isKeywordValEmpty,
        limit: isLimitValEmpty,
        country: isCountryEmpty,
        state: isStateEmpty,
      }));
      return; // Exit if validation fails
    }

    setSERPErrors((prevErrors) => ({
      ...prevErrors,
      keyword: false,
      limit: false,
      country: false,
      state: false,
    }));
    setScrapTypeError(false);
    setKeywordTableShow(true);
    const objForApi = {
      language_code: "en",
      location_code: parseInt(countryCityVal?.state), // countryCityVal?.state,
      limit: parseInt(limit),
      keyword: keywordVal,
    };
    setLoading(true);
    setCountdown(10);
    if (keywordVal && limit && scrapType && scrapType === "SERP scraper") {
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : prev));
      }, 1000);
      axios
        .post(SERPApiUrl, [objForApi])
        .then((response) => {
          setSERPScrappedData(response?.data);
          console.log(response, "SERPRES");
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.error) {
            if (error?.response?.data?.error === "Unexpected status code") {
              setErrorResText(
                "An unexpected error occured. Please try again later"
              );
            } else {
              setErrorResText(error?.response?.data?.error);
            }

            setErrorShow(true);
            setTimeout(() => {
              setErrorShow(false);
              setErrorResText(""); // Clear the error message
            }, 3000);
          }
        })
        .finally(() => {
          console.log("finally");
          clearInterval(timer);
          setLoading(false);
        });
    }
  };

  const handleSearchBtnSitemap = () => {
    const isFieldValEmpty = !fieldVal?.trim();
    const isScrapTypeEmpty = !scrapType?.trim();

    const isEmptyObject = (obj: any) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    // Check if either fieldVal or scrapType is empty
    if (isFieldValEmpty || isScrapTypeEmpty) {
      setFieldError(isFieldValEmpty);
      setScrapTypeError(isScrapTypeEmpty);
      return; // Exit if validation fails
    }

    setFieldError(false);
    setScrapTypeError(false);
    setKeywordTableShow(true);
    const objForApi = {
      url: fieldVal,
      custom_js: "meta = {}; meta.url = document.URL; meta;",
    };
    setLoading(true);
    setCountdown(10);
    if (fieldVal && scrapType && scrapType === "Sitemap scraper") {
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      axios
        .post(siteMapSearchUrl, [objForApi])
        .then((response) => {
          console.log(response, "resforchecksitemap");

          setSitemapScrappedData(response?.data?.locData);
          console.log(response);
        })
        .catch((error) => {
          console.log(error, "error");
          if (error?.response?.data?.error) {
            // Use the utility function to check if the error object is empty
            if (!isEmptyObject(error.response.data.error)) {
              setErrorResText("No sitemap found for this url");
            } else {
              setErrorResText("An unexpected error occurred. Try again later");
            }

            setErrorShow(true);
            setTimeout(() => {
              setErrorShow(false);
              setErrorResText(""); // Clear the error message
            }, 3000);
          }
        })
        .finally(() => {
          console.log("finally");
          clearInterval(timer);
          setLoading(false);
        });
    }
  };

  const handleScrapUrls = () => {
    // Check if either fieldVal or scrapType is empty

    const objForApi = {
      urls: selectedUrls,
    };
    setLoadingForUrl(true);
    // setCountdown(10);
    if (selectedUrls && selectedUrls?.length > 0) {
      // const timer = setInterval(() => {
      //   setCountdown((prev) => (prev > 0 ? prev - 1 : prev));
      // }, 1000);
      axios
        .post(scrapUrlsApiUrl, objForApi, {
          responseType: "blob",
        })
        .then((response) => {
          // setSERPScrappedData(response?.data)
          console.log(response?.data, "SCRAPURLSRESPONSE");
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "scraped_data.csv"); // Set the CSV filename
          document.body.appendChild(link);
          link.click();
          link.remove(); // Remove the temporary link element
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log("finally");
          // clearInterval(timer);
          setLoadingForUrl(false);
        });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldVal(event.target.value);
    setFieldError(false);
  };

  // SERP Handlechange

  const handleSERPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Clear the error when input is updated
    setSERPErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the field being updated
    }));

    if (name === "keyword") {
      setKeywordVal(value);
    } else if (name === "limit") {
      setLimit(value);
    }
  };

  // const handleCheckboxChange = (event, rowUrl) => {
  //   if (event.target.checked) {
  //     // Add URL to the selected array if checked
  //     setSelectedUrls((prevSelected) => [...prevSelected, rowUrl]);
  //   } else {
  //     // Remove URL from the selected array if unchecked
  //     setSelectedUrls((prevSelected) =>
  //       prevSelected.filter((url) => url !== rowUrl)
  //     );
  //   }
  // };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowUrl?: string
  ) => {
    if (!rowUrl) return; // Guard clause to exit if rowUrl is undefined

    if (event.target.checked) {
      // Add URL to the selected array if checked
      setSelectedUrls((prevSelected: string[]) => [...prevSelected, rowUrl]);
    } else {
      // Remove URL from the selected array if unchecked
      setSelectedUrls((prevSelected: string[]) =>
        prevSelected.filter((url) => url !== rowUrl)
      );
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setScrapType(event.target.value);
    setCountryCityVal({ country: "", state: "" });
    setKeywordTableShow(false);
    setScrapTypeError(false);
    setPage(0);
    setPageSite(0);
    setPageScrappedData([]);
    setSERPScrappedData([]);
    setFieldVal("");
    setKeywordVal("");
    setLimit("");
    setPageScrapTableShow(false);
    setSERPTableShow(false);
  };

  console.log(countryCityVal, "countryCityVal");

  const handleCountryCityChange = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    name: string
  ) => {
    if (reason === "clear") {
      setSERPErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error for the field being updated
      }));
    }

    setCountryCityVal((prevValue) => ({
      ...prevValue,
      [name]: value || "",
    }));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value) / 3); // Ensures rowsPerPage is correctly calculated
    setPage(0); // Reset to first page
  };

  // sitemap scraper
  const handleChangePageSite = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageSite(newPage);
  };

  const handleChangeRowsPerPageSite = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageSite(parseInt(event.target.value));
    setPageSite(0);
  };

  // serp scraper
  const handleChangePageSerp = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageSerp(newPage);
  };

  const handleChangeRowsPerPageSerp = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageSerp(parseInt(event.target.value));
    setPageSerp(0);
  };

  // ! old xportcsv

  type PageRow = {
    sNo: number;
    items: string;
    values: string;
  };

  const PageScrapExportToCSV = () => {
    // Build CSV content for each row, matching the table structure
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        // Header row: "Items,Values"
        "Items,Values",

        // Iterate over each row to match table structure
        ...pageScrappedData?.map((row) => {
          return [
            `H${row.level},${row.h_title}`, // First row for H{level} and h_title
            `Paragraph,"${row.primary_content || "N/A"}"`, // Second row for Paragraph and primary_content
            `Sub-paragraph,"${row.secondary_content || "N/A"}"`, // Third row for Sub-paragraph and secondary_content
          ].join("\n");
        }),
      ].join("\n");

    // Create a link element for download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv"); // Set the file name

    // Append to the document and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    { id: "1", label: "S.No" },
    { id: "2", label: "Website Url" },
  ];

  const SERPColumns = [
    { id: "1", label: "Select" },
    { id: "2", label: "Rank" },

    { id: "3", label: "Domain" },

    { id: "4", label: "Title" },

    { id: "5", label: "Description" },
    { id: "6", label: "URL" },
  ];

  const pageColumns = [
    { id: "1", label: "Items" },
    { id: "2", label: "Values" },
  ];

  const rows = [
    { sNo: "1", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "2", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "3", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "4", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "5", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "6", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "7", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "8", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "9", websiteUrl: "www.google.com", websiteLink: "Explore Now" },

    { sNo: "10", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "11", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "12", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "13", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "14", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "15", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "16", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "17", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "18", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "19", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
    { sNo: "20", websiteUrl: "www.google.com", websiteLink: "Explore Now" },
  ];

  // old code
  // React.useEffect(() => {
  //   // Transform the response into a structure grouped by levels
  //   const groupedData = pageScrappedData?.reduce((acc, item) => {
  //     const { level, h_title } = item;

  //     if (!acc[level]) {
  //       acc[level] = [];
  //     }
  //     acc[level].push(h_title);
  //     return acc;
  //   }, {});

  //   // Prepare the pageRows based on the levels
  //   const newPageRows = Object.entries(groupedData).map(([level, titles]) => ({
  //     sNo: level,
  //     items: `H${level}`,
  //     values: titles?.join(", "),
  //   }));

  //   setPageRows(newPageRows);
  // }, [pageScrappedData]);

  const navigateToSinglePage = (websiteUrl: any) => {
    navigate("/Data-Page", { state: { websiteUrl } });
  };

  function EndIconCusom(props: any) {
    return (
      <FiberManualRecordIcon
        {...props}
        sx={{ height: "10px", width: "10px" }}
      />
    );
  }

  return (
    <div className="webscrap-container">
      <Grid
        container
        spacing={2}
        padding={2}
        mt={-2}
        display={"flex"}
        direction={"column"}
        // flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        sx={{
          background: "rgba(255, 255, 255, 0.1)", // Light translucent background
          // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)", // Shadow effect
          backdropFilter: "blur(4px)", // Blurring background
          WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
          borderRadius: "10px", // Rounded corners
          border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border
        }}
      >
        <Typography variant="h3" fontFamily={"serif"} color="white">
          Web Scraping
        </Typography>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth error={scrapTypeError}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "white", // Label color when not focused
                "&.Mui-focused": {
                  color: "white", // Label color when focused
                },
              }}
            >
              Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={scrapType}
              label="Type"
              onChange={handleSelectChange}
              sx={{
                color: "white", // Text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white", // Border color when not focused
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: scrapTypeError ? "red" : "white", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: scrapTypeError ? "red" : "white", // Border color when focused
                },
                "& .MuiSvgIcon-root": {
                  color: "white", // Dropdown arrow color
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background for options
                    backdropFilter: "blur(18px)", // Blurred background effect
                    "& .MuiMenuItem-root": {
                      color: "white", // Text color for options
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker on hover
                    },
                    "& .Mui-selected": {
                      backgroundColor: "rgba(255, 255, 255, 0.3) !important", // Selected item background
                    },
                  },
                },
              }}
            >
              <MenuItem value={"Sitemap scraper"}>Sitemap scraper</MenuItem>
              <MenuItem value={"SERP scraper"}>SERP scraper</MenuItem>
              <MenuItem value={"Page Scraper"}>Page Scraper</MenuItem>
            </Select>
            {scrapTypeError && (
              <FormHelperText sx={{ color: "red" }}>
                Type is required
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {scrapType === "SERP scraper" && (
          <Grid container spacing={2}>
            {/* <Grid
              size={{ xs: 12, md: 6 }}
              // display={"flex"}
              // justifyContent={"center"}
              // alignItems={"center"}
            >
              <FormControl fullWidth error={SERPErrors?.country}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: "white", // Label color when not focused
                    "&.Mui-focused": {
                      color: "white", // Label color when focused
                    },
                  }}
                >
                  Country code
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={countryCityVal?.country}
                  label=" Country code"
                  name="country"
                  onChange={handleCountryCityChange}
                  sx={{
                    color: "white", // Text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // Border color when not focused
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: SERPErrors?.country ? "red" : "white", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: SERPErrors?.country ? "red" : "white", // Border color when focused
                    },
                    "& .MuiSvgIcon-root": {
                      color: "white", // Dropdown arrow color
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background for options
                        backdropFilter: "blur(18px)", // Blurred background effect
                        "& .MuiMenuItem-root": {
                          color: "white", // Text color for options
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker on hover
                        },
                        "& .Mui-selected": {
                          backgroundColor:
                            "rgba(255, 255, 255, 0.3) !important", // Selected item background
                        },
                        maxHeight: "300px",
                      },
                      onScroll: (event) => {
                        const bottom =
                          event?.target?.scrollHeight -
                            event.target.scrollTop ===
                          event?.target?.clientHeight;
                        if (bottom) {
                          setLoadedCount((prevCount) =>
                            Math.min(prevCount + 10, countries?.length)
                          );
                        }
                      },
                    },
                  }}
                >
                  {countries?.slice(0, loadedCount).map((country) => (
                    <MenuItem value={country}>{country}</MenuItem>
                  ))}
                </Select>
                {SERPErrors?.country && (
                  <FormHelperText sx={{ color: "red" }}>
                    Country Code is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            <Grid size={{ xs: 12, md: 6 }}>
              <FormControl
                fullWidth
                error={!!SERPErrors?.country}
                sx={{ width: "100%" }}
              >
                <Autocomplete
                  fullWidth
                  id="country-autocomplete"
                  options={countries} // Limited options initially
                  getOptionLabel={(option) => option} // Assuming `countries` is an array of strings
                  onInputChange={(event, newInputValue) => {
                    // Reset loaded count if needed when input changes
                  }}
                  onChange={(event, value, reason) =>
                    handleCountryCityChange(event, value, reason, "country")
                  }
                  PaperComponent={CustomPaper} // Use Custom Paper for glossy background
                  // ListboxProps={{
                  //   onScroll: (event) => {
                  //     const bottom =
                  //       event?.target?.scrollHeight - event.target.scrollTop ===
                  //       event?.target?.clientHeight;
                  //     if (bottom) {
                  //       setLoadedCount((prevCount) =>
                  //         Math.min(prevCount + 10, countries?.length)
                  //       );
                  //     }
                  //   },
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Code"
                      fullWidth
                      sx={{
                        cursor: "pointer",
                        color: "white",
                        "& .MuiInputBase-input": {
                          color: "white", // Text color inside input
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: SERPErrors?.country ? "red" : "white",
                          },
                          "&:hover fieldset": {
                            borderColor: SERPErrors?.country ? "red" : "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: SERPErrors?.country ? "red" : "white",
                          },
                          // backgroundColor: "rgba(255, 255, 255, 0.1)", // Glossy input background
                          // backdropFilter: "blur(10px)", // Blurred effect for glossy look
                        },
                        "& .MuiInputLabel-root": {
                          color: "white", // Label color
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "white", // Label color when focused
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: "white", // Dropdown arrow color
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "white",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "white",
                    },
                  }}
                />
                {SERPErrors?.country && (
                  <FormHelperText sx={{ color: "red" }}>
                    Country Code is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* <Grid
              size={{ xs: 12, md: 6 }}
              // display={"flex"}
              // justifyContent={"center"}
              // alignItems={"center"}
            >
              <FormControl fullWidth error={SERPErrors?.state}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: "white", // Label color when not focused
                    "&.Mui-focused": {
                      color: "white", // Label color when focused
                    },
                  }}
                >
                  Location
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={countryCityVal?.state}
                  label="Location"
                  name="state"
                  onChange={handleCountryCite2Change}
                  sx={{
                    color: "white", // Text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // Border color when not focused
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: SERPErrors?.state ? "red" : "white", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: SERPErrors?.state ? "red" : "white", // Border color when focused
                    },
                    "& .MuiSvgIcon-root": {
                      color: "white", // Dropdown arrow color
                    },
                  }}
                  disabled={loadingStates}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background for options
                        backdropFilter: "blur(18px)", // Blurred background effect
                        "& .MuiMenuItem-root": {
                          color: "white", // Text color for options
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker on hover
                        },
                        "& .Mui-selected": {
                          backgroundColor:
                            "rgba(255, 255, 255, 0.3) !important", // Selected item background
                        },
                        maxHeight: "300px",
                      },
                      onScroll: (event) => {
                        const bottom =
                          event?.target?.scrollHeight -
                            event.target.scrollTop ===
                          event?.target?.clientHeight;
                        if (bottom) {
                          setLoadedCountForState((prevCount) =>
                            Math.min(prevCount + 10, states?.length)
                          );
                        }
                      },
                    },
                  }}
                >
                 
               
                  {states?.slice(0, loadedCountForState).map((state, i) => (
                    <MenuItem key={i} value={state?.state_code}>
                      {state?.state_name}
                    </MenuItem>
                  ))}
                </Select>
                {SERPErrors?.state && (
                  <FormHelperText sx={{ color: "red" }}>
                    State is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            <Grid size={{ xs: 12, md: 6 }}>
              <FormControl
                fullWidth
                error={!!SERPErrors?.state}
                sx={{ width: "100%" }}
              >
                <Autocomplete
                  fullWidth
                  id="loc-autocomplete"
                  options={filteredStates} // Limited options initially or by search
                  getOptionLabel={(option) => option?.state_name}
                  onInputChange={(event, newInputValue) => {
                    setSearchText(newInputValue);
                    setLoadedCountForState(10); // Reset loaded count on new search
                  }}
                  onChange={(event, value, reason) =>
                    handleCountryCityChange(
                      event,
                      value ? value.state_code : "",
                      reason,
                      "state"
                    )
                  }
                  PaperComponent={CustomPaper} // Use Custom Paper for glossy background
                  // ? old onscroll
                  // ListboxProps={{
                  //   onScroll: (event) => {
                  //     const bottom =
                  //       event.target.scrollHeight - event.target.scrollTop ===
                  //       event.target.clientHeight;
                  //     if (
                  //       bottom &&
                  //       loadedCountForState < states.length &&
                  //       !searchText
                  //     ) {
                  //       setLoadedCountForState((prevCount) =>
                  //         Math.min(prevCount + 10, states.length)
                  //       );
                  //     }
                  //   },
                  // }}
                  // ? new onscroll
                  ListboxProps={{
                    ref: listboxRef, // Attach ref to Listbox
                    onScroll: handleScroll,
                  }}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      label="Location"
                      fullWidth
                      sx={{
                        cursor: "pointer",
                        color: "white",
                        "& .MuiInputBase-input": { color: "white" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: SERPErrors?.state ? "red" : "white",
                          },
                          "&:hover fieldset": {
                            borderColor: SERPErrors?.state ? "red" : "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: SERPErrors?.state ? "red" : "white",
                          },
                        },
                        "& .MuiInputLabel-root": { color: "white" },
                        "& .MuiInputLabel-root.Mui-focused": { color: "white" },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "white" },
                    "& .MuiAutocomplete-popupIndicator": { color: "white" },
                    "& .MuiAutocomplete-clearIndicator": { color: "white" },
                  }}
                />
                {SERPErrors?.state && (
                  <FormHelperText sx={{ color: "red" }}>
                    Location is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        {(scrapType === "Sitemap scraper" || scrapType === "Page Scraper") && (
          <Grid size={{ xs: 12, md: 6 }} mt={3}>
            <TextField
              id="outlined-basic"
              label={"Url"}
              variant="outlined"
              onChange={handleChange}
              value={fieldVal}
              fullWidth
              error={fieldError} // Display error state
              helperText={fieldError ? "URL is required" : ""} // Show helper text if error exists
              sx={{
                input: { color: "white" }, // Text color inside TextField
                "& label": {
                  color: "white", // Label color when not focused
                },
                "& label.Mui-focused": {
                  color: "white", // Label color when focused
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white", // Border color when not focused
                  },
                  "&:hover fieldset": {
                    borderColor: fieldError ? "red" : "white", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: fieldError ? "red" : "white", // Border color when focused
                  },
                },
              }}
            />
          </Grid>
        )}
        {scrapType === "SERP scraper" && (
          <Grid container spacing={1} mt={3} sx={{ gap: gapValue }}>
            <Grid size={{ xs: 12, md: 8 }}>
              <TextField
                id="outlined-basic"
                label={"Keyword"}
                variant="outlined"
                onChange={handleSERPChange}
                value={keywordVal}
                name="keyword"
                fullWidth
                error={SERPErrors.keyword} // Display error state
                helperText={SERPErrors.keyword ? "URL is required" : ""} // Show helper text if error exists
                sx={{
                  input: { color: "white" }, // Text color inside TextField
                  "& label": {
                    color: "white", // Label color when not focused
                  },
                  "& label.Mui-focused": {
                    color: "white", // Label color when focused
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Border color when not focused
                    },
                    "&:hover fieldset": {
                      borderColor: SERPErrors.keyword ? "red" : "white", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: SERPErrors.keyword ? "red" : "white", // Border color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <TextField
                id="outlined-basic"
                label={"No of Results"}
                variant="outlined"
                onChange={handleSERPChange}
                value={limit}
                name="limit"
                fullWidth
                error={SERPErrors.limit} // Display error state
                helperText={SERPErrors.limit ? "URL is required" : ""} // Show helper text if error exists
                sx={{
                  input: { color: "white" }, // Text color inside TextField
                  "& label": {
                    color: "white", // Label color when not focused
                  },
                  "& label.Mui-focused": {
                    color: "white", // Label color when focused
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Border color when not focused
                    },
                    "&:hover fieldset": {
                      borderColor: SERPErrors.limit ? "red" : "white", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: SERPErrors.limit ? "red" : "white", // Border color when focused
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          size={{ xs: 12, md: 6 }}
          display={"flex"}
          justifyContent={"center"}
          mt={1}
        >
          <button
            className="webscraping-button"
            onClick={
              scrapType === "Page Scraper"
                ? handleSearchBtn
                : scrapType === "SERP scraper"
                ? handleSearchBtnSERP
                : scrapType === "Sitemap scraper"
                ? handleSearchBtnSitemap
                : () => {
                    if (!scrapType) {
                      setScrapTypeError(true);
                    }
                  }
            }
            disabled={loading}
            style={{
              display: "flex",

              //   justifyContent: "center",
              alignItems: "center",
              cursor: loading ? "not-allowed" : "pointer",
            }}
          >
            <Typography
              variant="h6"
              display={"flex"}
              alignItems={"center"}
              gap={1}
              //   gap={1}
            >
              {/* <SearchIcon sx={{ fontSize: 30 }} /> */}

              {loading ? (
                // <CircularProgress size={24} sx={{ color: "white" }} />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <span style={{ marginLeft: "10px" }}>
                    {/* Please wait... {countdown} seconds remaining */}
                    Please wait ...
                  </span>
                  <CircularProgress size={24} sx={{ color: "white" }} />
                </div>
              ) : (
                <>
                  Run Scrap
                  <PlayArrowIcon />
                </>
              )}
            </Typography>
          </button>
        </Grid>
      </Grid>

      {sitemapTableShow && scrapType === "Sitemap scraper" && (
        <>
          <Paper
            sx={{
              color: "white",
              width: "100%",
              overflow: "hidden",
              mt: 3,
              background: "rgba(255, 255, 255, 0.1)", // Light translucent background
              // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)", // Shadow effect
              backdropFilter: "blur(4px)", // Blurring background
              WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
              // borderRadius: "10px", // Rounded corners
              // border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border
            }}
          >
            <TableContainer sx={{ color: "white" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ color: "white" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      color: "white",
                      textAlign: "center",
                      // background: "rgba(255, 255, 255, 0.1)",
                      borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                      borderRight: "none",
                      boxShadow: "0 0 1px rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        sx={{
                          color: "white",
                          textAlign: "center",
                          backdropFilter: "blur(20px)",

                          borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                          background: "rgba(255, 255, 255, 0.1)",
                        }}
                        // key={column.id}
                      >
                        <Typography variant="h6"> {column.label}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sitemapScrappedData
                    .slice(
                      pageSite * rowsPerPageSite,
                      pageSite * rowsPerPageSite + rowsPerPageSite
                    )
                    .map((row, i) => {
                      return (
                        <TableRow>
                          <TableCell
                            sx={{ textAlign: "center", color: "white" }}
                          >
                            <Typography>{i + 1}</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: "center", color: "white" }}
                          >
                            <Typography>
                              <Link
                                sx={{
                                  padding: "0.5rem",
                                }}
                                color={"#fff"}
                                href={row}
                                underline="always"
                                target="_blank"
                              >
                                {row}{" "}
                              </Link>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={sitemapScrappedData?.length}
              rowsPerPage={rowsPerPageSite}
              page={pageSite}
              onPageChange={handleChangePageSite}
              onRowsPerPageChange={handleChangeRowsPerPageSite}
              sx={{
                color: "white", // Text color
                "& .MuiTablePagination-selectIcon": {
                  color: "white", // Dropdown arrow near rows per page
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Dropdown background
                      backdropFilter: "blur(18px)", // Blurred background for the dropdown
                      "& .MuiMenuItem-root": {
                        color: "white", // Text color for options
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker background on hover
                      },
                      "& .Mui-selected": {
                        backgroundColor: "rgba(255, 255, 255, 0.3) !important", // Background color for selected option
                      },
                    },
                  },
                },
              }}
            />
          </Paper>
        </>
      )}

      {SERPTableShow && scrapType === "SERP scraper" && (
        <>
          <Box
            mt={2}
            mb={-2}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {selectedUrls?.length > 0 && (
              <button
                className="export_btn"
                onClick={handleScrapUrls}
                disabled={loadingForUrl}
                style={{
                  cursor: loadingForUrl ? "not-allowed" : "pointer",
                }}
              >
                <Typography
                  variant="h6"
                  fontSize={14}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  letterSpacing={1.5}
                >
                  {loadingForUrl ? (
                    // <CircularProgress size={24} sx={{ color: "white" }} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <span style={{ marginLeft: "10px" }}>
                        Please wait ...
                      </span>
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    </div>
                  ) : (
                    <>
                      Scrap Selected and Download CSV <GetAppIcon />
                    </>
                  )}
                </Typography>
              </button>
            )}
          </Box>

          <Paper
            sx={{
              color: "white",
              width: "100%",
              overflow: "hidden",
              mt: 3,
              background: "rgba(255, 255, 255, 0.1)", // Light translucent background
              backdropFilter: "blur(4px)", // Blurring background
              WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
            }}
          >
            <TableContainer sx={{ color: "white" }}>
              <Table aria-label="vertical table" sx={{ color: "white" }}>
                <TableHead>
                  <TableRow
                    sx={{
                      color: "white",
                      textAlign: "center",
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(20px)",
                      borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                      borderRight: "none",
                      boxShadow: "0 0 1px rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    {SERPColumns?.map((column) => (
                      <TableCell
                        sx={{
                          color: "white",
                          textAlign: "center",
                          borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                        }}
                        // key={column.id}
                      >
                        <Typography variant="h6"> {column?.label}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SERPScrappedData?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            fontWeight: 800,
                          }}
                        >
                          <label className="custom-checkbox">
                            <input
                              name="dummy"
                              type="checkbox"
                              onChange={(event) =>
                                handleCheckboxChange(event, row?.url)
                              } // Checkbox handler
                              checked={selectedUrls?.includes(row?.url)} // Check if the URL is selected
                            />
                            <span className="checkmark"></span>
                          </label>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            fontWeight: 800,
                          }}
                        >
                          {row?.rank_group}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography>{row?.domain}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography>{row?.title}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography>{row?.description}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            // className="export_btn"
                            sx={{
                              color: "white",
                            }}
                          >
                            <Link
                              sx={{
                                padding: "0.5rem",
                              }}
                              color={"#fff"}
                              href={row?.url}
                              underline="always"
                              target="_blank"
                            >
                              {row?.url}{" "}
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={SERPScrappedData?.length}
              rowsPerPage={rowsPerPageSerp}
              page={pageSerp}
              onPageChange={handleChangePageSerp}
              onRowsPerPageChange={handleChangeRowsPerPageSerp}
              sx={{
                color: "white",
                "& .MuiTablePagination-selectIcon": {
                  color: "white", // Dropdown arrow color
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Dropdown background
                      backdropFilter: "blur(18px)", // Blurred background for the dropdown
                      "& .MuiMenuItem-root": {
                        color: "white", // Text color for options
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker background on hover
                      },
                      "& .Mui-selected": {
                        backgroundColor: "rgba(255, 255, 255, 0.3) !important", // Background color for selected option
                      },
                    },
                  },
                },
              }}
            />
          </Paper>
        </>
      )}
      {pageScrapTableShow && scrapType === "Page Scraper" && (
        <>
          <Box
            mt={2}
            mb={-2}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <button className="export_btn" onClick={PageScrapExportToCSV}>
              <Typography
                variant="h6"
                fontSize={14}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                Export to CSV <GetAppIcon />
              </Typography>
            </button>
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Paper
              sx={{
                // display: "flex",
                // justifyContent: "center",
                color: "white",
                // width: "300px",
                overflow: "hidden",
                mt: 3,
                background: "rgba(255, 255, 255, 0.1)", // Light translucent background
                backdropFilter: "blur(4px)", // Blurring background
                WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
              }}
            >
              {/* <SimpleTreeView
                sx={{ width: "100%" }}
                slots={{
                  expandIcon: AddBoxIcon,
                  collapseIcon: IndeterminateCheckBoxIcon,
                  endIcon: EndIconCusom,
                }}
              >
                
                <TreeItem itemId="root" label={fieldVal}>
                  {pageRows?.map((row, index) => (
                    <TreeItem
                      key={`parent-${index}`} // Ensure unique key for parent
                      itemId={`parent-${index}`} // Unique itemId for parent
                      label={row.items} // H+level as the parent node
                    >
                     
                      {row.values?.split(", ").map((value, idx) => (
                        <TreeItem
                          key={`child-${index}-${idx}`} // Ensure unique key for each child
                          itemId={`child-${index}-${idx}`} // Unique itemId for each child
                          label={value} // Each value from the values string
                        />
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              </SimpleTreeView>  */}

              <TableContainer sx={{ color: "white" }}>
                <Table aria-label="vertical table" sx={{ color: "white" }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        color: "white",
                        textAlign: "center",
                        background: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(20px)",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                        borderRight: "none",
                        boxShadow: "0 0 1px rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      {pageColumns.map((column) => (
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "center",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                          }}
                          // key={column.id}
                        >
                          <Typography variant="h6"> {column?.label}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageScrappedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <React.Fragment key={index}>
                            {/* First row for H{level} and h_title */}
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  fontWeight: 800,
                                }}
                              >
                                H{row?.level}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700 }}
                                >
                                  {row?.h_title}
                                </Typography>
                              </TableCell>
                            </TableRow>

                            {/* Second row for Paragraph and primary_content */}
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  fontWeight: 800,
                                }}
                              >
                                Paragraph
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700 }}
                                >
                                  {row?.primary_content || "N/A"}
                                </Typography>
                              </TableCell>
                            </TableRow>

                            {/* Third row for Sub-paragraph and secondary_content */}
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  fontWeight: 800,
                                  flexWrap: "nowrap",
                                }}
                              >
                                Sub - paragraph
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700 }}
                                >
                                  {row?.secondary_content || "N/A"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100].map(
                  (option) => option * 3
                )}
                component="div"
                count={pageScrappedData?.length * 3}
                rowsPerPage={rowsPerPage * 3}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  color: "white",
                  "& .MuiTablePagination-selectIcon": {
                    color: "white", // Dropdown arrow color
                  },
                  // "& .MuiTablePagination-toolbar": {
                  //   backgroundColor: "rgba(255, 255, 255, 0.1)", // Translucent toolbar background
                  //   backdropFilter: "blur(10px)", // Blurred toolbar background
                  // },
                  // "& .MuiTablePagination-select": {
                  //   color: "white", // Text color for the selected value in the dropdown
                  // },
                  // "& .MuiTablePagination-actions": {
                  //   "& button": {
                  //     color: "white", // Color for the forward/backward buttons
                  //   },
                  // },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Dropdown background
                        backdropFilter: "blur(18px)", // Blurred background for the dropdown
                        "& .MuiMenuItem-root": {
                          color: "white", // Text color for options
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker background on hover
                        },
                        "& .Mui-selected": {
                          backgroundColor:
                            "rgba(255, 255, 255, 0.3) !important", // Background color for selected option
                        },
                      },
                    },
                  },
                }}
              />
            </Paper>
          </Box>
        </>
      )}
      {errorShow && (
        <>
          <Paper
            sx={{
              padding: 1,
              overflow: "hidden",
              mt: 2,
              borderRadius: "10px",
              // background: "rgba(255, 255, 255, 0.1)",
              background: "rgba(245, 34, 45, 0.55)",
              backdropFilter: "blur(4px)", // Blurring background
              WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
              border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <ErrorOutlineIcon sx={{ fontSize: "30px" }} /> {errorResText}
            </Typography>
          </Paper>
        </>
      )}
    </div>
  );
}
