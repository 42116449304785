import "./Sidebar.scss";

import { Box, Button, Collapse, Typography } from "@mui/material";
import {  setExpandedBusiness } from "../../store/businessSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useBusinessProfileApis } from "../../custom_hooks/useBusinessProfileApis";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

type TransformedObject = {
  Name: string;
  Description: string;
  Website: string;
  ProductsServices: string[];
  LocationType: string;
  Locations: string[];
  CustomerPersonas: { personName: string; personDescription: string }[] | null;
  SellingPoints: string[] | null;
  BusinessObjective: string | null;
  Competitors: { name: string; website: string }[] | null;
  UniqueId: string;
};

interface BusinessSidebarProps {
  expandedBusiness: string | null;
  setExpandedBusiness: React.Dispatch<React.SetStateAction<string | null>>;
  searchTerm: string | null;
}

const BusinessSidebar: React.FC<BusinessSidebarProps> = ({searchTerm}) => {
  const navigate = useNavigate();
 
  const { GetBusinessProfilesData, sidebarDataLoading } = useBusinessProfileApis();
  console.log("sidebarDataLoading:", sidebarDataLoading)
  const dispatch = useDispatch();
  const businessProfiles = useSelector((state: any) => state.businessDetails.profiles); // Get profiles from Redux store
  const businessRef = useRef(true);

  const handleActiveMenu = (menuName: string) => {
    setActiveMenu(menuName);
  };
  // When `apiData` changes, dispatch the data to Redux
  useEffect(() => {
    if (businessRef.current) {
      GetBusinessProfilesData();
      businessRef.current = false;
      handleActiveMenu("Dashboard");
    }
  });

  const [filteredProfiles, setFilteredProfiles] = useState(businessProfiles);
   // Filter profiles based on the search term
   useEffect(() => {
    if (searchTerm) {
      const filtered = businessProfiles.filter((profile: any) =>
        profile.Name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProfiles(filtered); // Set filtered profiles
    } else {
      setFilteredProfiles(businessProfiles); // Show all profiles if no search term
    }
  }, [searchTerm, businessProfiles]);

  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const expandedBusiness = useSelector((state : any) => state.businessDetails.expandedBusiness); 

  

  const handleExpandBusiness = (businessData: TransformedObject) => {
    handleActiveMenu("Dashboard");
    dispatch(setExpandedBusiness( businessData.Name));
    navigate(`/business/dashboard`, { state: { businessData } });
  
  };


  const profileHandler = (businessData: TransformedObject) => {
    navigate(`/business/profile`, { state: { businessData } });
    handleActiveMenu("Profile");
  };

  const dashboardHandler = (businessData: TransformedObject) =>{
    navigate(`/business/dashboard`, { state: { businessData }});
    handleActiveMenu("Dashboard");
  }

  const reviewsHandler = (businessData: TransformedObject) =>{
    navigate(`/business/reviews`, { state: { businessData }});
    handleActiveMenu("Reviews");
  }

  const campaignHandler = (businessData: TransformedObject) =>{
    navigate(`/business/campaign`, { state: { businessData }});
    handleActiveMenu("Campaigns");
  }

  return (
    
    <Box className="sidebar-menus">
      <Box className="business-profiles">
        {
          sidebarDataLoading ? 
          <Stack spacing={1} sx={{marginLeft: 1.4, marginTop: 1}}>
            {[...Array(8)].map((_, index) => (
              <Skeleton
                key={index} 
                sx={{ bgcolor: '#bdbdbd' }}
                variant="text"
                animation="wave"
                width={150}
                height={15}
              />
            ))}
          </Stack>
          :

          (filteredProfiles.length > 0 && filteredProfiles) ?
            filteredProfiles?.map((item: any, index: number) => (
              <Box key={index} width="100%">
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "2px", marginLeft: 1 }}
                >
                  <Box
                    sx={{
                      width: "18px",
                      height: "16px",
                      borderRadius: "4px",
                      backgroundColor: "#E4F1F1",
                    }}
                  ></Box>
                  <Button
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={() => handleExpandBusiness(item)}
                    fullWidth
                    sx={{
                      backgroundColor:
                        expandedBusiness === item.Name
                          ? "rgba(255, 255, 255, 0.1)"
                          : "transparent",
                      textTransform: "none",
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {item.Name}
                  </Button>
                </Box>
  
                <Collapse in={expandedBusiness === item.Name}>
                  <Box className="submenu" sx={{ marginTop: 1, color: "white" }}>
                    <Typography
                      variant="body2"
                      onClick={() => dashboardHandler(item)}
                      className={`active-menu-item ${
                        activeMenu === "Dashboard" ? "active" : ""
                      }`}
                    >
                      Dashboard
                    </Typography>
  
                    <Typography
                      variant="body2"
                      onClick={() => reviewsHandler(item)}
                      className={`active-menu-item ${
                        activeMenu === "Reviews" ? "active" : ""
                      }`}
                    >
                      Reviews
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => campaignHandler(item)}
                      className={`active-menu-item ${
                        activeMenu === "Campaign" ? "active" : ""
                      }`}
                    >
                      Campaign
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`active-menu-item ${
                        activeMenu === "Profile" ? "active" : ""
                      }`}
                      onClick={() => profileHandler(item)}
                    >
                      Profile
                    </Typography>
                  </Box>
                </Collapse>
              </Box>
            ))
          :
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', marginLeft: '20px' }}>
          </Typography>
        
        }
      </Box>
    </Box>
  );
};


export default BusinessSidebar;
