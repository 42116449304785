import { Box, Typography } from '@mui/material'

import React from 'react';

const BusinessReviews = () => {


  return (
    <Box>  
      <Typography variant="h5">Business Reviews</Typography>  
    </Box>
  );
};

export default BusinessReviews;
