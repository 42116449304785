// Dashboard.tsx

import './AdminDashboard.scss';

import { HomeOutlined, NotificationOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

import BarChartComponent from '../../components/charts/BarChartComponent';
import CustomCard from '../../components/card/CustomCard';
import PieChartComponent from '../../components/charts/PieChartComponent';
import React from 'react';

const AdminDashboard: React.FC = () => {
  const cards = [
    { icon: UserOutlined, title: 'Users', text: '10', path: '/users' },
    { icon: SettingOutlined, title: 'Businesses', text: '30', path: '/business' },
    { icon: NotificationOutlined, title: 'Active Profiles', text: '50', path: '/active-profiles' },
    { icon: HomeOutlined, title: 'Data Syncs', text: '34', path: '/data-syncs' },
  ];


  const data = [
    { id: '1', category: 'A', value: 10 },
    { id: '2', category: 'B', value: 20 },
    { id: '3', category: 'C', value: 30 },
    { id: '4', category: 'A', value: 15 },
  ];

  return (
    <div className="dashboard">
      <div className='cardContainer'>
      {cards.map((card, index) => (
        <CustomCard key={index} icon={card.icon} title={card.title} text={card.text} path={card.path} />
      ))}
      </div>
      <div className='chartContainer'>
        <BarChartComponent data={data} />
        <PieChartComponent data={data} />
      </div>
    </div>
  );
};

export default AdminDashboard;
