import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './ChartComponent.scss';

const ChartComponent = () => {

  const [options] = useState<ApexOptions>({
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: false
      }
    },
    colors: ['#007bff', '#6f42c1', '#28a745', '#fd7e14'],
    dataLabels: {
      enabled: false,
      // formatter: (val : any, opts : any) => {
      //   return `${val}k`;  // Adjust to add units, or percentage
      // },
      // style: {
      //   colors: ['#000']
      // }
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    title: {
      text: 'Custom Chart with Growth Indicators',
      align: 'center',
      style: {
        fontSize: '18px',
        color: '#666'
      }
    },
    xaxis: {
      type: 'datetime',
      categories: ['2023-04-20', '2023-04-27', '2023-05-03', '2023-05-10', '2023-05-17', '2023-05-24', '2023-07-01'],
      labels: {
        format: 'MMM dd',
        style: {
        colors: '#8e8da4'
      }
      }
      
    },
    yaxis: [
      {
        min: 0,         
        max: 100, 
        // Left Y-axis (Primary)
        labels: {
          formatter: (val) => `${val}`,
          style: {
            colors: '#8e8da4'
          }
        }
      },
      {
        min: 0,         
        max: 100, 
        // Right Y-axis (Secondary)
        opposite: true,
        labels: {
          formatter: (val) => `${val}`,
          style: {
            colors: '#8e8da4'
          }
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value : any) => `${value}k` // Customize as per metric
      }
    },
    grid: {
      borderColor: '#e1e2e3',
      padding: {
        left: 10,
        right: 10
      }
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'left',
      labels: {
        useSeriesColors: true
      },
      // markers: {
      //   width: 12,
      //   height: 12
      // },
      itemMargin: {
        horizontal: 10,
        vertical: 5
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.4,
        opacityFrom: 0.9,
        opacityTo: 0.1,
        stops: [0, 90, 100]
      }
    }
  });

  const defaultSeriesData = [
    {
      name: 'CLK',
      data: [20, 30, 40, 50, 60, 70, 90],
    },
    {
      name: 'IMP',
      data: [10, 15, 25, 35, 45, 55, 65],
    },
    {
      name: 'CTR',
      data: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.9]
    },
    {
      name: 'POS',
      data: [40, 35, 30, 25, 20, 15, 10]
    }
  ];
  
  const [series, setSeries] = useState(defaultSeriesData);
  const [visible, setVisible] = useState([true, true, true, true]);
 

  const toggleSeries = (index: number) => {
    const updatedVisibility = visible.map((v, i) => (i === index ? !v : v));
    setVisible(updatedVisibility);

    const updatedSeries = defaultSeriesData.map((s, i) => ({
      ...s,
      data: updatedVisibility[i] ? s.data : [], // Empty data array hides the series
    }));
    setSeries(updatedSeries);
  };
  

  return (
    <div>
    <div className="custom-legend">
    {defaultSeriesData.map((s, index) => (
          <label key={index} className="legend-item">
            <input
              type="checkbox"
              checked={visible[index]}
              onChange={() => toggleSeries(index)}
            />
            <span
              className="legend-color"
              style={{
                backgroundColor: options.colors?.[index] || '#ccc',
              }}
            ></span>
            <span className="legend-label">{s.name}</span>
          </label>
        ))}
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default ChartComponent;
