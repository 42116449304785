import "./Homepage.scss";

import { Button, Layout, Typography } from "antd";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { Link, Outlet } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import { Header } from "antd/es/layout/layout";
import UserNavbar from "../../components/Navbar/UserNavbar";
import UserSidebar from "../../components/Sidebar/UserSidebar";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

const { Sider, Content } = Layout;

const UserHomepage = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const { userDetails, businessData } = useSelector((state: any) => state.auth);

  useLayoutEffect(() => {
    const handleResize = () => {
      const screenBelow1024px = window.innerWidth < 1024;
      setCollapsed(screenBelow1024px);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.clientHeight;
      setHeaderHeight(height);
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }} className="homepage">
      <Sider
        id="fixed-sidebar"
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          backgroundColor: "black",
        }}
        collapsed={collapsed}
        collapsible
        trigger={null}
        width={200}
        collapsedWidth={80}
      >
        <div className="homepage-logo-container">
          <Link to="/dashboard" className="logo">
            {collapsed ? `${businessData.name}` : `${businessData.name}`}
          </Link>
          <Button
            onClick={() => setCollapsed(!collapsed)}
            type="text"
            className="toggle-btn"
            icon={
              collapsed ? (
                <IoMdArrowDropright size={34} />
              ) : (
                <IoMdArrowDropleft size={34} />
              )
            }
          />
        </div>
        <div style={{ height: `calc(100vh - ${headerHeight}px)`, overflowY: 'auto' }}>
          <UserSidebar />
        </div>
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          minHeight: "100vh",
          transition: "margin-left 0.2s",
        }}
      >
        <Header
          ref={headerRef}
          id="fixed-header"
          style={{
            backgroundColor: "white",
            padding: "0 8px",
            fontSize: "1.05rem",
            borderBottom: "0.45px solid grey",
            zIndex: 1,
            position: "fixed",
            top: 0,
            width: `calc(100% - ${collapsed ? 80 : 200}px)`,
            transition: "width 0.2s",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="username">
            <Typography.Title
              level={5}
              style={{
                margin: 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                opacity: 0.85,
                fontSize: "16px",
                color: "#0B0951",
              }}
            >
              WELCOME - {userDetails.userName.toUpperCase()}
            </Typography.Title>
          </div>
          <div>
            <UserNavbar />
          </div>
        </Header>
        <Content style={{ marginTop: headerHeight + "px", padding: "6px" }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserHomepage;