// src/App.tsx

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AllRoutes } from './components/constants/routes';
import { ConfigProvider } from 'antd';
import { GlobalProvider } from '../src/utils/globalContext/GlobalContext';
import React from 'react';
import { ToastContainer } from 'react-toastify';

const router = createBrowserRouter(AllRoutes);

const theme = {
  token: {
    colorPrimary: '#003366',
  },
};

function App() {
  return (
    <ConfigProvider theme={theme}>
      <GlobalProvider>
        <div data-testid="app">
          <ToastContainer
            progressClassName="toastProgress"
            bodyClassName="toastBody"
          />
          <RouterProvider router={router} />
        </div>
      </GlobalProvider>
    </ConfigProvider>
  );
}

export default App;
