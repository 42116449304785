/* eslint-disable react-hooks/exhaustive-deps */

import './BusinessProfile.scss';

import { Box, Button, Card, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../utils/loader/Loader';
import ProductsTable from './ProductsTable';
import { useBusinessProfileApis } from '../../custom_hooks/useBusinessProfileApis';
import { useSelector } from 'react-redux';

const BusinessProfile = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { GetBusinessProfilesDataByUniqueID, businessProfilesDataLoading, businessUpdateProfilesDataLoading } = useBusinessProfileApis();
  
  const { businessData } = location.state || {};
  const businessRef = useRef(true);
  useEffect(() => {
    if(businessRef.current) {
      GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
      businessRef.current = false;
    }
  }, [businessData]);

  const profileData = useSelector((state: any) => state.businessDetails.profileDataByUniqueID); // Get profileDataByUniqueID from Redux store

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Loader spinning={businessProfilesDataLoading || businessUpdateProfilesDataLoading} >
      <Box className="profile-container">
        {/* Profile Header */}
      
      <Typography variant='h5' >Profile</Typography>
        {/* Profile Tabs */}
        <Tabs value={selectedTab} onChange={handleTabChange} className="profile-tabs">
          <Tab label="Overview" className="tab" />
          <Tab label="Products" className="tab" />
        </Tabs>

        {/* Tab Content */}
        <Box className="tab-content">
          {selectedTab === 0 && (
            <Box>
              {/* Business Description */}
              <Card className="business-info-card">


            
              <Box className="profile-header">
                  <Box className="profile-info">
                    {/* Placeholder for profile logo */}
                    <Box className="profile-logo" />
                    
                    {/* Profile Text */}
                    <Box className="profile-text">
                      <Typography variant="h5">{profileData?.Name}</Typography>
                    </Box>
                  </Box>
                  
                  <Box className="profile-info">
                    <Typography variant="body2">{profileData?.Website}</Typography>
                    <IconButton className="edit-icon" onClick={() => navigate(`/edit-business-profile/${profileData.Name}`, { state: { businessData } })}>
                        <EditIcon fontSize="small"  />
                    </IconButton>
                  </Box>
              </Box>
          

                <Box>
                  <Typography className="card-title">
                      Business Description 
                      
                  </Typography>
                  <Typography variant="body2">
                  {profileData?.Description}
                  </Typography>
                </Box>
                
              
              </Card>

              {/* Customer Persona */}
              <Card className="business-info-card">
                <Typography className="card-title">Customer Persona</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                  <Typography variant="body2">
                      Your customer persona will be generated once you add products.
                  </Typography>
                  <Button variant="contained" className="add-products-button" sx={{maxWidth: '300px'}} onClick={() => setSelectedTab(1) } >
                      Add Products
                  </Button>
                </Box>
              </Card>
            </Box>
          )}
          {selectedTab === 1 && 
          
          // <Box>Product tab content goes here...</Box>
          <ProductsTable />
          }
        </Box>
      </Box>
    </Loader>
  );
};

export default BusinessProfile;
