import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, IconButton } from '@mui/material';
import './TableComponent.scss';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Label from '../../components/Label';
interface DataType {
  query: string;
  clk: string;
  imp: string;
  ctr: string;
  pos: string;
}

type TableProps = {
    title: string;
}

const queriesData: DataType[] = [
  { query: 'query query query query query query', clk: '999', imp: '999.9k', ctr: '+1000%', pos: '99.9' },
  { query: 'query query query', clk: '999', imp: '+2100%', ctr: '+1000%', pos: '99.9' },
  // Add more rows here...
];


const TableComponent: React.FC<TableProps> = ({title}) => {
  return (
    <div className="Custom-table-wrapper">
       
      <TableContainer component={Paper} sx={{padding: '0 16px'}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-4px', marginTop: '1rem'}} >
            <Typography variant="subtitle1" >{title}</Typography>
            <Box>
            <IconButton >
                <EmojiEventsIcon/>
            </IconButton>
            <IconButton >
                <TrendingUpIcon/>
            </IconButton>
            <IconButton >
                <TrendingDownIcon/>
            </IconButton>

            </Box>
        </Box>
        <Table>
          <TableHead  sx={{borderBottom: '1px solid #e0e0e0'}}>
            <TableRow >
              <TableCell></TableCell>
              <TableCell sx={{maxWidth: '46px'}}>CLK</TableCell>
              <TableCell sx={{maxWidth: '46px'}}>IMP</TableCell>
              <TableCell sx={{maxWidth: '46px'}}>CTR</TableCell>
              <TableCell sx={{maxWidth: '46px'}}>POS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {queriesData.map((row, index) => (
              <TableRow key={index}>
                <TableCell >{row.query}</TableCell>
                <TableCell sx={{maxWidth: '65px'}}>
                    {row.clk}
                    <Label
                            increase={index % 2 === 0 ? true : false}
                            label={1000}
                            color={index % 2 === 0 ? 'success' : 'error'}  
                            size ="12px" 
                            iconsize="12px"
                        />
                </TableCell>
                <TableCell sx={{maxWidth: '65px'}} >{row.imp}
                     <Label
                            increase={index % 2 === 0 ? true : false}
                            label={1000}
                            color={index % 2 === 0 ? 'success' : 'error'}  
                            size ="12px" 
                            iconsize="12px"
                        />
                    </TableCell>
                    <TableCell sx={{maxWidth: '65px'}}>{row.ctr} 
                        <Label
                            increase={index % 2 === 0 ? true : false}
                            label={1000}
                            color={index % 2 === 0 ? 'success' : 'error'}  
                            size ="12px" 
                            iconsize="12px"
                        />
                    </TableCell>
                    <TableCell sx={{maxWidth: '65px'}}>{row.pos}
                        <Label
                            increase={index % 2 === 0 ? true : false}
                            label={1000}
                            color={index % 2 === 0 ? 'success' : 'error'}  
                            size ="12px" 
                            iconsize="12px"
                        />
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <IconButton >
                <ArrowForwardIcon/>
            </IconButton>
        </Box>
      </TableContainer>

    </div>
  );
};

export default TableComponent;
