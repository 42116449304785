/* eslint-disable react-hooks/exhaustive-deps */

import {
  Badge,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ExpandMore,
  Logout as LogoutIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon
} from "@mui/icons-material";
import  { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomToast from "../../utils/cutomToast/CustomToast";
import firebaseConfig from "../../firebaseConfig";
import { initializeApp } from "firebase/app";
import { logout } from "../../store/authSlice";
import { useLocation } from "react-router-dom";
import { useBusinessProfileApis } from "../../custom_hooks/useBusinessProfileApis";

interface PushNotification {
  Title: string;
  Body: string;
  CreatedDate: string;
}

const AdminNavbar = () => {
  const firebaseApp = initializeApp(firebaseConfig);
  const { userDetails } = useSelector((state: any) => state.auth);
  console.log(userDetails);
  const messaging = getMessaging(firebaseApp);
  const { doApi } = useApi();
  const dispatch = useDispatch();
  const firstTime = useRef(true);
  const [pushNotifications, setPushNotifications] = useState<
    PushNotification[]
  >([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const location = useLocation();
  
  const { businessData } = location.state || {};

  const { GetBusinessProfilesDataByUniqueID } = useBusinessProfileApis();

  useEffect(() => {
    if(businessData){
      GetBusinessProfilesDataByUniqueID(businessData?.UniqueId);
    }
  }, [businessData]);

  const profileData = useSelector((state: any) => state.businessDetails.profileDataByUniqueID); // Get profileDataByUniqueID from Redux store


  const handleMenuClick = async (action: string) => {
    if (action === "logout") {
      const logoutConfig: IAPIConfig = {
        URL: "/logout",
        method: "POST",
      };
      try {
        const response = await doApi(logoutConfig, "seedmain");
        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(logout());
      }
    }
    setAnchorEl(null);
  };

  const saveTokenForPushNotification = async (token: string) => {
    const input = {
      pushToken: token,
    };
    const saveTokenConfig: IAPIConfig = {
      URL: "/save-token",
      method: "POST",
      payLoad: input,
    };

    try {
      const response = await doApi(saveTokenConfig, "seedmain");
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllPushNotifications = async () => {
    const pushNotificationsConfig: IAPIConfig = {
      URL: "/get-all-pushnotificatins",
      method: "GET",
    };

    try {
      const response: any = await doApi(pushNotificationsConfig, "seedmain");
      if (response.data.success) {
        setPushNotifications(response.data.data);
        setNotificationCount(response.data.data.length);
      } else {
        setPushNotifications([]);
        setNotificationCount(0);
      }
    } catch (err: any) {
      CustomToast(err, "error");
    }
  };

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
          const token = await getToken(messaging, {
            vapidKey:
              "BK1KLGvUQ602zcedceVPvoxTybmDrmecvP4yntrfMJGQdrLLtrod5iS64e0NOknlv4g1d3ZOlEzeJRWZAphx1SU",
          });
          if (token) {
            if (firstTime.current) {
              saveTokenForPushNotification(token);
              getAllPushNotifications();
              firstTime.current = false;
            }
          } else {
            console.log("No FCM token available.");
          }
        } else {
          CustomToast(
            "Notification permission denied. Please enable notifications in your browser.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error getting notification permission or token:", error);
      }
    };
    requestPermission();
    onMessage(messaging, (payload: any) => {
      console.log("Message received: ", payload);
      setPushNotifications((prevNotifications: any) => [
        {
          Title: payload.notification.title,
          Body: payload.notification.body,
          CreatedDate: new Date().toLocaleString(),
        },
        ...prevNotifications,
      ]);
      setNotificationCount((prevCount) => prevCount + 1);
      CustomToast(
        `${payload.notification.title}\n${payload.notification.body}`,
        "success"
      );
    });
  }, [messaging, doApi]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const profileMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={() => handleMenuClick("profile")}>
        <PersonIcon /> <span style={{marginLeft:"8px"}}>Profile</span>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClick("logout")}>
        <LogoutIcon /> <span style={{marginLeft:"8px"}}>Logout</span>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="navbar">
      <Typography variant="subtitle2">
        {(profileData && Object.keys(profileData).length > 0) ? (
          <>
          {profileData.Name} 
          <span style={{ margin: "0 2px" }}></span> {/* Adds space */}
          {profileData.Website}
          <ArrowForwardIosIcon htmlColor = "#093535" sx={{fontSize:"12px", marginLeft:"5px", marginTop:"3px"}} />
         </>
        ) : (
          userDetails.organzationName || ""
        )}
      </Typography>

      <div className="navbar-icons">
        <IconButton
          onClick={() => {
            setDrawerVisible(true);
            setNotificationCount(0);
          }}
        >
          <Badge badgeContent={notificationCount} color="secondary">
            <NotificationsIcon  htmlColor = "#093535"/>
          </Badge>
        </IconButton>
        <Button
          endIcon={<ExpandMore />}
          onClick={handleProfileMenuOpen}
          color="primary"
          size = "large"
          variant="outlined"
        >
          {userDetails?.name || "User"}
        </Button>
        {profileMenu}
      </div>
      <Drawer
        anchor="right"
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        <div style={{ width: 300 }}>
          <Typography variant="h6" style={{ padding: 16 }}>
            Notifications
          </Typography>
          <List>
            {pushNotifications.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={item.Title}
                  secondary={
                    <div>
                      <div>{item.Body}</div>
                      <div style={{ color: "gray", fontSize: "12px" }}>
                        {new Date(item.CreatedDate).toLocaleString()}
                      </div>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default AdminNavbar;
