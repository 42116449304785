import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Label from '../../components/Label';
import './StatsCards.scss';


interface StatsCardProps {
  title: string;
  value: string;
  percentage: string;
  increase: boolean;
  description?: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ title, value, percentage, increase, description }) => {
  return (
    <Card className="stats-card">
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="h3">{value}</Typography>
        <Label
            increase={increase}
            label={percentage}
            color={increase ? 'success' : 'error'}
          /> {description}
      </CardContent>
    </Card>
  );
};

export default StatsCard;
