import './BusinessDashboard.scss';

import {
  Box,
  Card,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import StatsCard from './StatsCards';
import StatsCardWithStars from './StatsCardWithStars';
import TableComponent from './TableComponent';
import ChartComponent from './ChartComponent';

const BusinessDashboard = () => {


  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };


  const [month, setMonth] = useState<string>("3");


  const timePerios = [
    {
    id: 1,
    label: "3 months",
    value: 3
    },
    {
      id: 2,
      label: "6 months",
      value: 6
    },
    {
      id: 3,
      label: "12 months",
      value: 12
    },
  ]


  const [tabs, setTab] = useState('watch_list_columns');

  const handleViewOrientation = (_event : React.SyntheticEvent, newValue : string) => {
    setTab(newValue);
  };

  return (
    <Box className="dashboard-container">
        {/* Profile Header */}
      
      <Typography variant='h5'>Dashboard</Typography>
      {/* Profile Tabs */}
      <Box sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'flex-end', columnGap: '1.5rem'}} >
        <Tabs value={selectedTab} onChange={handleTabChange} className="dashboard-tabs">
          <Tab label="Dashboard" className="tab" />
          <Tab label="Opportunities" className="tab" />
          <Tab label="Settings" className="tab" />
        </Tabs>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month} // Make sure this matches one of the timePerios values
              onChange={(e)=>setMonth(e.target.value)}
              sx={{
                color: '#6e6b6b',
                height: '40px',
                outline: 'none',
                background: '#fff',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {timePerios.map((timePeriod) => (
                <MenuItem key={timePeriod.id} value={timePeriod.value}>
                  {timePeriod.label}
                </MenuItem>
              ))}
          </Select>

          </FormControl>
        </Box>
      </Box>

      {/* Tab Content */}
      <Box className="tab-content">
        {selectedTab === 0 && (
          <Box>
              <Card className='review-info-card' >
                <Box>
                  <Typography variant="h5" >Organic Search</Typography>
                  <Typography variant='body2' marginTop={1}>Source: Google Search Console</Typography>
                </Box>
                
                <Box>
                <ToggleButtonGroup
                    value={tabs}
                    exclusive
                    onChange={handleViewOrientation}
                    sx={{ height: '40px', color: '#fff' }}
                  >
                    <ToggleButton
                      disableRipple
                      value="CLK"
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: '#4285F4',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#4285F4', 
                          },
                        },
                      }}
                    >
                      CLK
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      value="IMP"
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: '#4285F4',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#4285F4',
                          },
                        },
                      }}
                    >
                      IMP
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      value="CTR"
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: '#4285F4',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#4285F4',
                          },
                        },
                      }}
                    >
                      CTR
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      value="POS"
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: '#4285F4',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#4285F4',
                          },
                        },
                      }}
                    >
                      POS
                    </ToggleButton>
                </ToggleButtonGroup>

                </Box>
              </Card>

              <Box>
                  <ChartComponent />
              </Box>
              
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1.4 }} >
              <Grid size={4}>
                <StatsCard title="Branded" description='vs prev. 28 days' value="42%" percentage="147%" increase={true} />
              </Grid>
              <Grid size={4} sx={{flexGrow: 1}} >
                <StatsCard title="Product" description='vs prev. 28 days' value="69%" percentage="20%" increase={false} />
              </Grid>
              <Grid size={4}>
                <StatsCard title="In Top 10" description='vs prev. 28 days' value="7" percentage="147%" increase={true} />
              </Grid>
              
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1.4 }} sx={{marginTop: 1}}>
              <Grid size={6}>
                <TableComponent title="Queries" />
              </Grid>
              <Grid size={6}>
                <TableComponent title="Content" />
              </Grid>
              <Grid size={6}>
                <TableComponent title="Query Clusters" />
              </Grid>
              <Grid size={6}>
                <TableComponent title="Content Groups" />
              </Grid>
            </Grid>

            
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1.4 }} marginTop={1} >
              <Grid size={6}>
                <StatsCard title="All Time Reviews" description='Growth in reviews' value="10.0k" percentage="147%" increase={true} />
              </Grid>
              <Grid size={6} sx={{flexGrow: 1}} >
                <StatsCard title="Reviews Past [selected period]" description='Growth in reviews' value="4,269" percentage="20%" increase={false} />
              </Grid>
              <Grid size={6}>
                <StatsCardWithStars title="All Time Average Rating" description='Average rating' value="4.0" percentage="147%" increase={true} />
              </Grid>
              <Grid size={6}>
                <StatsCardWithStars title="Ratings Past [selected period]" description='Average rating' value="4.5" percentage="147%" increase={true} />
              </Grid>
              
            </Grid>

          </Box>
        )}
        {selectedTab === 1 && 
          <Box>
          Opportunities tab content goes here...
          </Box>
        }
          {selectedTab === 2 && 
          <Box>
          Settings tab content goes here...
          </Box>
        }
      </Box>


     
    </Box>
  );
};

export default BusinessDashboard;
