import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// Define the props for the CustomDialog component
interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode; // Allows any valid React node as children
  description?: string; // Add an optional description prop
  width?: string;
  minWidth?: string;
}

const CustomDialog: React.FC<CustomDialogProps> = ({ open, onClose, title, description, width,minWidth, children }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md"   >
        <DialogTitle sx={{ pb: 0 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Add description below the title */}
      {description && (
        <Typography variant="body2" sx={{ mt: -0.2, ml: 3, mr: 3, color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
      
      <DialogContent  style={{ width: `${ width ? width : 'auto'}`, minWidth: `${ minWidth ? minWidth : 'auto'}` }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
