import { authReducer } from "./authSlice";
import { companyProfileReducer } from "./companyProfileSlice";
import { configureStore } from "@reduxjs/toolkit";
import { userEntityTypeReducer } from "./userEntityType";
import { userProfileReducer } from "./userProfileSlice";
import  businessProfile  from "./businessSlice";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    companyProfileDetails: companyProfileReducer,
    userProfileDetails: userProfileReducer,
    userEntityDetails: userEntityTypeReducer,
    businessDetails: businessProfile,
  }
})


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
